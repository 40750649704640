<!-- =========================================================================================
  File Name: DataListListView.vue
  Description: Data List - List View
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="reporter-list-view" class="data-list-container">
    <feather-icon class="sm:inline-flex xl:hidden cursor-pointer" icon="MenuIcon" style="height: 20px; margin-right: 10px;" @click.stop="showSidebar"></feather-icon>
    <vs-table ref="table" pagination search :max-items="renderMethod.itemsPerPage" :data="users">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">
        <!-- <div id="data-list-search-rank" style="width: 80px; height: 40px; padding-top: 0;">
          <vs-dropdown class="flex flex-wrap-reverse">
            <a class="flex items-center" href="#">
              <span class="mr-2">{{ factorToText(renderMethod.factor) }}</span>
              <i class="material-icons">expand_more</i>
            </a>
            <vs-dropdown-menu>
              <vs-dropdown-item @click="renderMethod.factor='name'">이름</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='account_id'">아이디</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='email'">이메일</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='level'">권한</vs-dropdown-item>
              <vs-dropdown-item @click="renderMethod.factor='hp'">전화</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
        <div style="margin-bottom:15px; padding-top: 0;">
          <vs-input v-on:keyup.enter="searchUserList()"  class="inputx" placeholder="검색" v-model="renderMethod.inputText"/>
        </div>
        <div class="vs-table--search" style="margin: 0px 15px">
          <vs-button
            ref="loadableButton"
            id="button-with-loading"
            color="primary"
            type="border"
            class="p-2 cursor-pointer vs-con-loading__container"
            @click="searchUserList()"
          >검색</vs-button>
        </div> -->
        <div class="vs-table--search" style="margin: 5px 15px 5px 0px">
          <vs-button
            ref="loadableButton"
            id="button-with-loading"
            color="primary"
            type="border"
            class="p-2 cursor-pointer vs-con-loading__container"
            @click="createUserModalActive = true"
          >추가</vs-button>
        </div>

        <!-- ITEMS PER PAGE -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
          <div class="p-4 border border-solid border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">
              {{ renderMethod.itemsPerPage }} of {{ numOfUsers }}
            </span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
          <vs-dropdown-menu>
            <vs-dropdown-item @click="changeItemPerPage(4)">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(10)">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(15)">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="changeItemPerPage(20)">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="id">아이디</vs-th>
        <vs-th sort-key="name">이름</vs-th>
        <vs-th sort-key="email">이메일</vs-th>
        <vs-th sort-key="level">권한</vs-th>
        <vs-th sort-key="tail">tailname</vs-th>
        <vs-th sort-key="delFrag">승인</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td><div v-on:click="showUserInfo(tr.id)" style="font-weight: bold">{{ tr.id }}</div></vs-td>
            <vs-td><div>{{ tr.name }}</div></vs-td>
            <vs-td><div>{{ tr.email }}</div></vs-td>
            <vs-td><div>{{ tr.level }}</div></vs-td>
            <vs-td><div>{{ tr.tail }}</div></vs-td>
            <vs-td><div>{{ tr.delFrag }}</div></vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>

    <!-- <div>
      <vs-pagination :total="numOfPages" v-model="currentx"></vs-pagination>
    </div> -->

    <!-- Start: 계정 생성 팝업 -->
    <div class="create-user-form">
      <vs-popup classContent="popup-example" title="계정 생성" :active.sync="createUserModalActive">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이름</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.createUserQueryData.name)" 
                      :danger="!regExpName.test(userModalData.createUserQueryData.name)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.createUserQueryData.name"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>닉네임</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.createUserQueryData.nickname)" 
                      :danger="!regExpName.test(userModalData.createUserQueryData.nickname)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.createUserQueryData.nickname"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>아이디</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpAccountId.test(userModalData.createUserQueryData.account_id)"
                      :danger="!regExpAccountId.test(userModalData.createUserQueryData.account_id)"
                      danger-text="아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다."
                      class="w-full" v-model="userModalData.createUserQueryData.account_id"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>비밀번호</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpPassword.test(userModalData.createUserQueryData.password)"
                      :danger="!regExpPassword.test(userModalData.createUserQueryData.password)"
                      danger-text="비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다."
                      class="w-full" type="password" v-model="userModalData.createUserQueryData.password"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이메일</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpEmail.test(userModalData.createUserQueryData.email)"
                      :danger="!regExpEmail.test(userModalData.createUserQueryData.email)"
                      danger-text="올바른 형식의 이메일을 입력해주세요."
                      class="w-full" type="email" v-model="userModalData.createUserQueryData.email"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>tailname</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="userModalData.createUserQueryData.tailname != ''"
                      :warning="userModalData.createUserQueryData.tailname == ''"
                      warning-text="tailname이 입력되지 않았습니다. 입력되지 않아도 계정 생성 가능합니다."
                      class="w-full" v-model="userModalData.createUserQueryData.tailname"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>입사일</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <datepicker type="date" :clearable="false" v-model="userModalData.createUserQueryData.entryDate" lang="kr" style="width: 200px;" />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>휴가일수</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px; display: flex; jectify-content: left;">
            <vs-input-number v-model="userModalData.createUserQueryData.maxTimeoff"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>항목</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <div class="select_section">
              <!-- <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="부서" label="text"
                v-model="userModalData.createUserQueryData.department" :clearable="false"
                :options="userModalData.optionData.departmentOption"/> -->
              <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="권한" label="text"
                v-model="userModalData.createUserQueryData.level" :clearable="false"
                :options="userModalData.optionData.levelOption"/>
              <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="승인/거부" label="text"
                v-model="userModalData.createUserQueryData.grant" :clearable="false"
                :options="userModalData.optionData.grantOption"/>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto" style="margin-bottom: 60px;">
            <vs-button class="mr-3 mb-2" @click="createUser()">생성</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mb-2"
              @click="createUserModalInfoResetModalActive=true"
            >초기화</vs-button>
          </div>
          <div class="demo-alignment">
            <vs-popup classContent="popup-example" title="주의!!" :active.sync="createUserModalInfoResetModalActive">
              <p style="padding: 20px">작성한 정보가 모두 초기화됩니다. 계속 진행하시겠습니까?</p>

              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserCreateModal(); createUserModalInfoResetModalActive=false" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="createUserModalInfoResetModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>

            </vs-popup>
          </div>
        </div>
      </vs-popup>
    </div>
    <!-- End: 계정 생성 팝업 -->

    <!-- Start: 계정 수정 팝업 -->
    <vs-popup classContent="popup-example" title="계정 수정" :active.sync="editUserModalActive">
      <div class="edit-user-form">
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이름</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.editUserQueryData.name)" 
                      :danger="!regExpName.test(userModalData.editUserQueryData.name)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.editUserQueryData.name"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>닉네임</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpName.test(userModalData.editUserQueryData.nickname)" 
                      :danger="!regExpName.test(userModalData.editUserQueryData.nickname)"
                      danger-text="완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다."
                      class="w-full" v-model="userModalData.editUserQueryData.nickname"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>아이디</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpAccountId.test(userModalData.editUserQueryData.account_id)"
                      :danger="!regExpAccountId.test(userModalData.editUserQueryData.account_id)"
                      danger-text="아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다."
                      class="w-full" v-model="userModalData.editUserQueryData.account_id"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>비밀번호</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpPassword.test(userModalData.editUserQueryData.password)"
                      :warning="!regExpPassword.test(userModalData.editUserQueryData.password)"
                      warning-text="비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다."
                      class="w-full" type="password" v-model="userModalData.editUserQueryData.password"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>이메일</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="regExpEmail.test(userModalData.editUserQueryData.email)"
                      :danger="!regExpEmail.test(userModalData.editUserQueryData.email)"
                      danger-text="올바른 형식의 이메일을 입력해주세요."
                      class="w-full" type="email" v-model="userModalData.editUserQueryData.email"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>tailname</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <vs-input :success="userModalData.editUserQueryData.tailname != ''"
                      :warning="userModalData.editUserQueryData.tailname == ''"
                      warning-text="tailname이 입력되지 않았습니다. 입력되지 않아도 계정 생성 가능합니다."
                      class="w-full" v-model="userModalData.editUserQueryData.tailname"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>입사일</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px;">
            <datepicker type="date" :clearable="false" v-model="userModalData.editUserQueryData.entryDate" lang="kr" style="width: 200px;" />
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>휴가일수</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full" style="height: 48px; display: flex; jectify-content: left;">
            <vs-input-number v-model="userModalData.editUserQueryData.maxTimeoff"/>
          </div>
        </div>
        <div class="vx-row mb-6">
          <div class="vx-col sm:w-1/3 w-full">
            <span>항목</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <div class="select_section">
              <!-- <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="부서" label="text"
                v-model="userModalData.editUserQueryData.department" :clearable="false"
                :options="userModalData.optionData.departmentOption"/> -->
              <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="권한" label="text"
                v-model="userModalData.editUserQueryData.level" :clearable="false"
                :options="userModalData.optionData.levelOption"/>
              <v-select style="width: 100px; margin-right: 15px; display: inline-block" placeholder="승인/거부" label="text"
                v-model="userModalData.editUserQueryData.grant" :clearable="false"
                :options="userModalData.optionData.grantOption"/>
            </div>
          </div>
        </div>
        <div class="vx-row">
          <div class="vx-col sm:w-2/3 w-full ml-auto" style="margin-bottom: 60px;">
            <vs-button class="mr-3 mb-2" @click="editUser()">수정</vs-button>
            <vs-button
              color="warning"
              type="border"
              class="mr-3 mb-2"
              @click="editUserModalInfoCancelModalActive=true"
            >취소</vs-button>
            <vs-button v-if="$route.query.deleted" color="danger" class="mb-2" @click="deleteUser()">삭제</vs-button>
          </div>
          <div class="demo-alignment2">
            <vs-popup classContent="popup-example2" title="수정 취소" :active.sync="editUserModalInfoCancelModalActive">
              <p style="padding: 20px">취소하시겠습니까?</p>
              <div style="padding:20px; display: inline-block;">
              <vs-button @click="resetQueryDataOfUserEditModal(); editUserModalInfoCancelModalActive=false; editUserModalActive=false;" color="primary" type="filled">예</vs-button>
              </div>
              <div style="margin-bottom: 20px; display: inline-block;">
              <vs-button @click="editUserModalInfoCancelModalActive=false" color="primary" type="filled">아니오</vs-button>
              </div>
            </vs-popup>
          </div>
        </div>
      </div>
    </vs-popup>
    <!-- End: 계정 수정 팝업 -->

  </div>
</template>

<script>
import moment from 'moment';
export default {
  components: {},
  data() {
    return {
      regExpName: /^[가-힣a-zA-Z0-9_ ]{2,20}$/,
      regExpAccountId: /^[a-zA-Z0-9]{6,12}$/,
      regExpPassword: /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/,
      regExpEmail: /^[0-9a-zA-Z_-]([-_.]?[0-9a-zA-Z_-])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
      regExpHp: /^\d{3}-\d{4}-\d{4}$/,
      createUserModalActive: false,
      editUserModalActive: false,
      createUserModalInfoResetModalActive: false,
      editUserModalInfoCancelModalActive: false,
      users: [],
      currentx: 1,
      numOfUsers: 0,
      numOfPages: 0,
      searchText: "",
      userModalData: {
        createUserQueryData: {
          name: '',
          nickname: '',
          account_id: '',
          password: '',
          email: '',
          hp: '',
          tailname: '',
          department: "",
          level: "",
          showWriterList: "",
          showStatistics: "",
          grant: "",
          entryDate: new Date(),
          maxTimeoff: 0,
        },
        editUserQueryData: {
          name: '',
          level: '',
          nickname: '',
          target_account_id: '',
          account_id: '',
          password: '',
          email: '',
          hp: '',
          tailname: '',
          department: "",
          showWriterList: "",
          showStatistics: "",
          grant: "",
          entryDate: new Date(),
          maxTimeoff: 0,
        },
        editUserQueryDataOrigin: {
          name: '',
          account_id: '',
          password: '',
          email: '',
          hp: '',
          tailname: '',
          department: "",
          showWriterList: "",
          showStatistics: "",
          grant: "",
        },
        optionData: {
          levelOption: [
            { text: "데스크", value: "9" },
            { text: "부데스크", value: "8" },
            { text: "일반기자", value: "7" },
            { text: "외부기자", value: "3" },
          ],
          departmentOption: [
            { text: "미분류", value: "g0" },
            { text: "정치/사회", value: "g1" },
            { text: "시장경제", value: "g2" },
            { text: "정책경제", value: "g6" },
            { text: "생활경제", value: "g7" },
            { text: "산업", value: "g3" },
            { text: "스포츠", value: "g4" },
            { text: "연예", value: "g5" },
            { text: "사진", value: "g8" }
          ],
          showWriterListOption: [
            { text: "노출", value: "1" },
            { text: "숨김", value: "0" }
          ],
          showStatisticsOption: [
            { text: "노출", value: "1" },
            { text: "숨김", value: "0" }
          ],
          grantOption: [
            { text: "승인", value: "1" },
            { text: "거부", value: "0" }
          ]
        },
      },
      renderMethod: {
        delFrag: 1,
        level: "",
        startRow: 0,
        itemsPerPage: 15,
        factor: "name",
        inputText: ""
      },
      isMounted: false
    };
  },
  beforeRouteLeave (to, from, next) {
    this.createUserModalActive = false;
    this.editUserModalActive = false;
    this.createUserModalInfoResetModalActive = false;
    this.editUserModalInfoCancelModalActive = false;
    setTimeout(() => { next(); }, 100);
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    }
  },
  watch: {
    '$route.query': function() {
      this.getUserList();
    },
    userModalData: function() {

      console.log(`userModalData.editUserQueryData.level: ${this.userModalData.editUserQueryData.level}`)
    }
  },
  methods: {
    changeItemPerPage(pageNum) {
      this.renderMethod.itemsPerPage = pageNum;
      this.getUserList();
    },

    factorToText(menu) {
      let menuText;
      switch (menu) {
        case "name":
          menuText = "이름";
          break;
        case "account_id":
          menuText = "아이디";
          break;
        case "email":
          menuText = "이메일";
          break;
        case "level":
          menuText = "권한";
          break;
        case "hp":
          menuText = "전화";
          break;
        case "tailname":
          menuText = "TAILNAME";
          break;
        case "grant":
          menuText = "승인";
          break;
      }
      return menuText;
    },

    isExistUser(account_id, func) {

      var thisObj = this
      
      thisObj.$http
        .post("/api/manageUsers/isExistUser", {account_id: account_id})
        .then(function(response) {
          
          console.log("exist response: "+response.data.exist)
          if(response.data.exist == "0") {
            
            func(false)
          }
          else if(response.data.exist == "1") {

            func(true)
          }
          else {

            thisObj.$vs.notify({
              title:'계정 생성 실패',
              text:"알 수 없는 오류로 인해 계정 생성에 실패했습니다.",
              color:'danger'})

            console.log("invalid data: "+response.data.exist)
          }
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
          }
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    mapUserInfoAtEditModal(userInfo) {

      this.userModalData.editUserQueryData.name = userInfo.name
      this.userModalData.editUserQueryData.nickname = userInfo.nickname
      this.userModalData.editUserQueryData.account_id = userInfo.id
      this.userModalData.editUserQueryData.password = ''
      this.userModalData.editUserQueryData.email = userInfo.email
      this.userModalData.editUserQueryData.hp = userInfo.hp
      this.userModalData.editUserQueryData.tailname = userInfo.tail
      
      this.userModalData.editUserQueryData.level = this.userModalData.optionData.levelOption.find(data => data.value == userInfo.level);//userInfo.level ? userInfo.level : ""
      this.userModalData.editUserQueryData.department = this.userModalData.optionData.departmentOption.find(data => data.value == userInfo.option1);//userInfo.option1 ? userInfo.option1 : ""
      this.userModalData.editUserQueryData.showWriterList = this.userModalData.optionData.showWriterListOption.find(data => data.value == userInfo.optino2);//userInfo.optino2 ? userInfo.optino2 : ""
      this.userModalData.editUserQueryData.showStatistics = this.userModalData.optionData.showStatisticsOption.find(data => data.value == userInfo.not_involve);//userInfo.not_involve ? userInfo.not_involve : ""
      this.userModalData.editUserQueryData.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.delFrag);//userInfo.delFrag ? userInfo.delFrag : ""
      this.userModalData.editUserQueryData.entryDate = new Date(userInfo.entry_date);
      this.userModalData.editUserQueryData.maxTimeoff = userInfo.max_timeoff;




      this.userModalData.editUserQueryDataOrigin.name = userInfo.name
      this.userModalData.editUserQueryDataOrigin.nickname = userInfo.nickname
      this.userModalData.editUserQueryDataOrigin.account_id = userInfo.id
      this.userModalData.editUserQueryDataOrigin.password = ''
      this.userModalData.editUserQueryDataOrigin.email = userInfo.email
      this.userModalData.editUserQueryDataOrigin.hp = userInfo.hp
      this.userModalData.editUserQueryDataOrigin.tailname = userInfo.tail
      
      this.userModalData.editUserQueryDataOrigin.level = this.userModalData.optionData.levelOption.find(data => data.value == userInfo.level);//userInfo.level ? userInfo.level : ""
      this.userModalData.editUserQueryDataOrigin.department = this.userModalData.optionData.departmentOption.find(data => data.value == userInfo.option1);//userInfo.option1 ? userInfo.option1 : ""
      this.userModalData.editUserQueryDataOrigin.showWriterList = this.userModalData.optionData.showWriterListOption.find(data => data.value == userInfo.optino2);//userInfo.optino2 ? userInfo.optino2 : ""
      this.userModalData.editUserQueryDataOrigin.showStatistics = this.userModalData.optionData.showStatisticsOption.find(data => data.value == userInfo.not_involve);//userInfo.not_involve ? userInfo.not_involve : ""
      this.userModalData.editUserQueryDataOrigin.grant = this.userModalData.optionData.grantOption.find(data => data.value == userInfo.delFrag);//userInfo.delFrag ? userInfo.delFrag : ""
    },

    showUserInfo(account_id) {

      var thisObj = this;

      thisObj.$http
          .post("/api/manageUsers/getReporter", {account_id: account_id})
          .then(function(response) {

            console.log(response.data)
            thisObj.mapUserInfoAtEditModal(response.data)
            thisObj.editUserModalActive = true
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },
    deleteUser() {
      const thisObj = this;
      thisObj.userModalData.editUserQueryData.target_account_id = thisObj.userModalData.editUserQueryDataOrigin.account_id;

      thisObj.userModalData.editUserQueryData.grant = 9;
      thisObj.userModalData.editUserQueryData.email = thisObj.userModalData.editUserQueryData.email || "";
      thisObj.userModalData.editUserQueryData.department = thisObj.userModalData.editUserQueryData.department ? thisObj.userModalData.editUserQueryData.department.value : '';
      thisObj.userModalData.editUserQueryData.level = thisObj.userModalData.editUserQueryData.level ? thisObj.userModalData.editUserQueryData.level.value : '';
      thisObj.userModalData.editUserQueryData.showWriterList = thisObj.userModalData.editUserQueryData.showWriterList ? thisObj.userModalData.editUserQueryData.showWriterList.value : '';
      thisObj.userModalData.editUserQueryData.showStatistics = thisObj.userModalData.editUserQueryData.showStatistics ? thisObj.userModalData.editUserQueryData.showStatistics.value : '';
      thisObj.userModalData.editUserQueryData.entryDate = thisObj.userModalData.editUserQueryData.entryDate ? moment(thisObj.userModalData.editUserQueryData.entryDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      thisObj.userModalData.editUserQueryData.maxTimeoff = thisObj.userModalData.editUserQueryData.maxTimeoff ? thisObj.userModalData.editUserQueryData.maxTimeoff : 0;
      
      thisObj.$http.post("/api/manageUsers/editReporter", thisObj.userModalData.editUserQueryData)
        .then(function(response) {
          
          if(response.data === "success") {

            thisObj.$vs.notify({
              title:'계정 수정 완료',
              text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 완료.",
              color:'success'})
            
            thisObj.editUserModalActive = false
            thisObj.resetQueryDataOfUserEditModal()
            thisObj.getUserList()
          }
          else {
            thisObj.$vs.notify({
              title:'계정 수정 실패',
              text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 실패.",
              color:'danger'})
          }

          thisObj.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch(function(error) {
          thisObj.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },
    editUser() {

      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification("edit_user")) {
        return;
      }

      // if account id edited.
      if(thisObj.userModalData.editUserQueryDataOrigin.account_id != thisObj.userModalData.editUserQueryData.account_id) {

        thisObj.isExistUser(thisObj.userModalData.editUserQueryData.account_id, function(exist) {

          if(exist) {
            
            thisObj.$vs.notify({
              title:'중복 아이디',
              text:"중복된 아이디입니다.",
              color:'warning'})

            return
          }

          thisObj.runEditUser()
        })
      }
      else {
          thisObj.runEditUser()
      }

    },

    createUser() {
      
      var thisObj = this

      if(!thisObj.isPassedIntegrityVerification("create_user")) {
        return;
      }

      thisObj.isExistUser(thisObj.userModalData.createUserQueryData.account_id, function(exist) {
        
        if(exist) {
          
          thisObj.$vs.notify({
            title:'중복 아이디',
            text:"중복된 아이디입니다.",
            color:'warning'})

          return;
        }

        thisObj.userModalData.createUserQueryData.department = thisObj.userModalData.createUserQueryData.department ? thisObj.userModalData.createUserQueryData.department.value : '';
        thisObj.userModalData.createUserQueryData.level = thisObj.userModalData.createUserQueryData.level.value ? thisObj.userModalData.createUserQueryData.level.value : '';
        // thisObj.userModalData.createUserQueryData.showWriterList = thisObj.userModalData.createUserQueryData.showWriterList.value ? thisObj.userModalData.createUserQueryData.showWriterList.value : '';
        // thisObj.userModalData.createUserQueryData.showStatistics = thisObj.userModalData.createUserQueryData.showStatistics.value ? thisObj.userModalData.createUserQueryData.showStatistics.value : '';
        thisObj.userModalData.createUserQueryData.grant = thisObj.userModalData.createUserQueryData.grant.value ? thisObj.userModalData.createUserQueryData.grant.value : '';
        thisObj.userModalData.createUserQueryData.entryDate = thisObj.userModalData.createUserQueryData.entryDate ? moment(thisObj.userModalData.createUserQueryData.entryDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        thisObj.userModalData.createUserQueryData.maxTimeoff = thisObj.userModalData.createUserQueryData.maxTimeoff ? thisObj.userModalData.createUserQueryData.maxTimeoff : 0;
        
        thisObj.$http
          .post("/api/manageUsers/createReporter", thisObj.userModalData.createUserQueryData)
          .then(function(response) {
            
            if(response.data === "success") {

              thisObj.$vs.notify({
                title:'계정 생성 완료',
                text:"계정 '"+thisObj.userModalData.createUserQueryData.name+"' 생성 완료.",
                color:'success'})
              
              thisObj.createUserModalActive = false  
              thisObj.resetQueryDataOfUserCreateModal()
              thisObj.getUserList()
            }
            else {

              console.log('response) ', response)

              thisObj.$vs.notify({
                title:'계정 생성 실패',
                text:"계정 '"+thisObj.userModalData.createUserQueryData.name+"' 생성 실패.",
                color:'danger'})
            }

            thisObj.$vs.loading.close("#button-with-loading > .con-vs-loading");
          })
          .catch(function(error) {
            var errorText = error.toString();
            if(errorText.includes('401')){
              alert('로그인 해주세요!')
              console.log(thisObj.$router)
              thisObj.$router.push({path: '/pages/login?to='+ thisObj.$router.history.current.path, query: thisObj.$router.history.current.query})
            }
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
      })
    },

    runEditUser() {

      var thisObj = this

      thisObj.userModalData.editUserQueryData.target_account_id = thisObj.userModalData.editUserQueryDataOrigin.account_id;

      thisObj.userModalData.editUserQueryData.department = thisObj.userModalData.editUserQueryData.department ? thisObj.userModalData.editUserQueryData.department.value : '';
      thisObj.userModalData.editUserQueryData.level = thisObj.userModalData.editUserQueryData.level ? thisObj.userModalData.editUserQueryData.level.value : '';
      thisObj.userModalData.editUserQueryData.showWriterList = thisObj.userModalData.editUserQueryData.showWriterList ? thisObj.userModalData.editUserQueryData.showWriterList.value : '';
      thisObj.userModalData.editUserQueryData.showStatistics = thisObj.userModalData.editUserQueryData.showStatistics ? thisObj.userModalData.editUserQueryData.showStatistics.value : '';
      thisObj.userModalData.editUserQueryData.grant = thisObj.userModalData.editUserQueryData.grant ? thisObj.userModalData.editUserQueryData.grant.value : '';
      thisObj.userModalData.editUserQueryData.entryDate = thisObj.userModalData.editUserQueryData.entryDate ? moment(thisObj.userModalData.editUserQueryData.entryDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      thisObj.userModalData.editUserQueryData.maxTimeoff = thisObj.userModalData.editUserQueryData.maxTimeoff ? thisObj.userModalData.editUserQueryData.maxTimeoff : 0;
      thisObj.$http
          .post("/api/manageUsers/editReporter", thisObj.userModalData.editUserQueryData)
          .then(function(response) {
            
            if(response.data === "success") {

              thisObj.$vs.notify({
                title:'계정 수정 완료',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 완료.",
                color:'success'})
              
              thisObj.editUserModalActive = false
              thisObj.resetQueryDataOfUserEditModal()
              thisObj.getUserList()
            }
            else {
              thisObj.$vs.notify({
                title:'계정 수정 실패',
                text:"계정 '"+thisObj.userModalData.editUserQueryDataOrigin.account_id+"' 수정 실패.",
                color:'danger'})
            }

            thisObj.$vs.loading.close("#button-with-loading > .con-vs-loading");
          })
          .catch(function(error) {
            thisObj.$vs.notify({
              title: "Error",
              text: error,
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-circle"
            });
          });
    },

    resetQueryDataOfUserCreateModal() {
      this.userModalData.createUserQueryData.name
      = this.userModalData.createUserQueryData.nickname
      = this.userModalData.createUserQueryData.account_id
      = this.userModalData.createUserQueryData.password
      = this.userModalData.createUserQueryData.email
      = this.userModalData.createUserQueryData.hp
      = this.userModalData.createUserQueryData.tailname
      = this.userModalData.createUserQueryData.department
      = this.userModalData.createUserQueryData.level
      = this.userModalData.createUserQueryData.showWriterList
      = this.userModalData.createUserQueryData.showStatistics
      = this.userModalData.createUserQueryData.grant
      = ''
      this.userModalData.createUserQueryData.entryDate = new Date();
      this.userModalData.createUserQueryData.maxTimeoff = 0;
    },
    resetQueryDataOfUserEditModal() {
      this.userModalData.editUserQueryData.name
      = this.userModalData.editUserQueryData.nickname
      = this.userModalData.editUserQueryData.account_id
      = this.userModalData.editUserQueryData.password
      = this.userModalData.editUserQueryData.email
      = this.userModalData.editUserQueryData.hp
      = this.userModalData.editUserQueryData.tailname
      = this.userModalData.editUserQueryData.department
      = this.userModalData.editUserQueryData.level
      = this.userModalData.editUserQueryData.showWriterList
      = this.userModalData.editUserQueryData.showStatistics
      = this.userModalData.editUserQueryData.grant
      = ''
      this.userModalData.editUserQueryData.entryDate = new Date();
      this.userModalData.editUserQueryData.maxTimeoff = 0;
    },
    isPassedIntegrityVerification(type) {

      if(type === "create_user") {

        var fillDataCheck = (this.userModalData.createUserQueryData.name && this.userModalData.createUserQueryData.name != '')
                && (this.userModalData.createUserQueryData.nickname && this.userModalData.createUserQueryData.nickname != '')
                && (this.userModalData.createUserQueryData.account_id && this.userModalData.createUserQueryData.account_id != '')
                && (this.userModalData.createUserQueryData.password && this.userModalData.createUserQueryData.password != '')
                && (this.userModalData.createUserQueryData.email && this.userModalData.createUserQueryData.email != '')
                // && (this.userModalData.createUserQueryData.hp && this.userModalData.createUserQueryData.hp != '')
                // && (this.userModalData.createUserQueryData.tailname && this.userModalData.createUserQueryData.tailname != '')
                // && (this.userModalData.createUserQueryData.department && this.userModalData.createUserQueryData.department != '')
                && (this.userModalData.createUserQueryData.level && this.userModalData.createUserQueryData.level != '')
                // && (this.userModalData.createUserQueryData.showWriterList && this.userModalData.createUserQueryData.showWriterList != '')
                // && (this.userModalData.createUserQueryData.showStatistics && this.userModalData.createUserQueryData.showStatistics != '')
                && (this.userModalData.createUserQueryData.grant && this.userModalData.createUserQueryData.grant != '')

        var regExpName = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        var nameCheck = regExpName.test(this.userModalData.createUserQueryData.name)
        
        var regExpNickname = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        var nicknameCheck = regExpNickname.test(this.userModalData.createUserQueryData.nickname)

        var regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
        var accountIdCheck = regExpAccountId.test(this.userModalData.createUserQueryData.account_id)

        var regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
        var passwordCheck = regExpPassword.test(this.userModalData.createUserQueryData.password)

        var regExpEmail = /^[0-9a-zA-Z_-]([-_.]?[0-9a-zA-Z_-])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
        var emailCheck = regExpEmail.test(this.userModalData.createUserQueryData.email)

        // var regExpHp = /^\d{3}-\d{4}-\d{4}$/
        var hpCheck = true
        // var hpCheck = regExpHp.test(this.userModalData.createUserQueryData.hp)
        
        if(!fillDataCheck) {

          this.$vs.notify({
            title:'미입력',
            text:"빠짐없이 입력 하세요.",
            color:'warning'})

            return false;
        }
        
        if(!nameCheck) {

          this.$vs.notify({
            title:'이름',
            text:"완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다.",
            color:'warning'})
        }
        
        if(!nicknameCheck) {

          this.$vs.notify({
            title:'닉네임',
            text:"완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다.",
            color:'warning'})
        }
        if(!accountIdCheck) {

          this.$vs.notify({
            title:'아이디',
            text:"아이디는 6~12글자, 공백 및 특수문자를 포함할 수 없습니다.",
            color:'warning'})
        }
        if(!passwordCheck) {

          this.$vs.notify({
            title:'비밀번호',
            text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
            color:'warning'})
        }
        if(!emailCheck) {

          this.$vs.notify({
            title:'이메일',
            text:"올바른 형식의 이메일을 입력해주세요.",
            color:'warning'})
        }
        if(!hpCheck) {

          this.$vs.notify({
            title:'전화번호',
            text:"올바른 형식의 전화번호를 입력해주세요.",
            color:'warning'})
        }

        return nameCheck && accountIdCheck && passwordCheck && emailCheck && hpCheck
      }
      else if(type === "edit_user") {

        var nameChanged = this.userModalData.editUserQueryData.name !== this.userModalData.editUserQueryDataOrigin.name
        var nicknameChanged = this.userModalData.editUserQueryData.nickname !== this.userModalData.editUserQueryDataOrigin.nickname
        var accountIdChanged = this.userModalData.editUserQueryData.account_id !== this.userModalData.editUserQueryDataOrigin.account_id
        var passwordChanged = this.userModalData.editUserQueryData.password !== this.userModalData.editUserQueryDataOrigin.password
        var emailChanged = this.userModalData.editUserQueryData.email !== this.userModalData.editUserQueryDataOrigin.email
        // var hpChanged = this.userModalData.editUserQueryData.hp !== this.userModalData.editUserQueryDataOrigin.hp
        // var tailnameChanged = this.userModalData.editUserQueryData.tailname !== this.userModalData.editUserQueryDataOrigin.tailname
        // var departmentChanged = this.userModalData.editUserQueryData.department !== this.userModalData.editUserQueryDataOrigin.department
        var levelChanged = this.userModalData.editUserQueryData.level !== this.userModalData.editUserQueryDataOrigin.level
        // var showWriterListChanged = this.userModalData.editUserQueryData.showWriterList !== this.userModalData.editUserQueryDataOrigin.showWriterList
        // var showStatisticsChanged = this.userModalData.editUserQueryData.showStatistics !== this.userModalData.editUserQueryDataOrigin.showStatistics
        var grantChanged = this.userModalData.editUserQueryData.grant !== this.userModalData.editUserQueryDataOrigin.grant

        fillDataCheck = (nameChanged ? (this.userModalData.editUserQueryData.name && this.userModalData.editUserQueryDataOrigin.name != '') : true)
                && (nicknameChanged ? (this.userModalData.editUserQueryData.nickname && this.userModalData.editUserQueryDataOrigin.nickname != '') : true)
                && (accountIdChanged ? (this.userModalData.editUserQueryData.account_id && this.userModalData.editUserQueryDataOrigin.account_id != '') : true)
                && (emailChanged ? (this.userModalData.editUserQueryData.email && this.userModalData.editUserQueryDataOrigin.email != '') : true)
                // && (hpChanged ? (this.userModalData.editUserQueryData.hp && this.userModalData.editUserQueryDataOrigin.hp != '') : true)
                // && (tailnameChanged ? (this.userModalData.editUserQueryData.tailname && this.userModalData.editUserQueryDataOrigin.tailname != '') : true)
                // && (departmentChanged ? (this.userModalData.editUserQueryData.department && this.userModalData.editUserQueryDataOrigin.department != '') : true)
                && (levelChanged ? (this.userModalData.editUserQueryData.level && this.userModalData.editUserQueryDataOrigin.level != '') : true)
                // && (showWriterListChanged ? (this.userModalData.editUserQueryData.showWriterList && this.userModalData.editUserQueryDataOrigin.showWriterList != '') : true)
                // && (showStatisticsChanged ? (this.userModalData.editUserQueryData.showStatistics && this.userModalData.editUserQueryDataOrigin.showStatistics != '') : true)
                && (grantChanged ? (this.userModalData.editUserQueryData.grant && this.userModalData.editUserQueryDataOrigin.grant != '') : true)

        regExpName = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        nameCheck = nameChanged ? regExpName.test(this.userModalData.editUserQueryData.name): true 

        regExpNickname = /^[가-힣a-zA-Z0-9_ ]{2,20}$/
        nicknameCheck = nameChanged ? regExpNickname.test(this.userModalData.editUserQueryData.nickname): true 

        regExpAccountId = /^[a-zA-Z0-9]{6,12}$/
        accountIdCheck = accountIdChanged ? regExpAccountId.test(this.userModalData.editUserQueryData.account_id) : true

        regExpPassword = /^(?=.*[a-zA-Z])((?=.*\d)|(?=.*\W)).{6,20}$/
        passwordCheck = passwordChanged ? regExpPassword.test(this.userModalData.editUserQueryData.password) : true

        regExpEmail = /^[0-9a-zA-Z_-]([-_.]?[0-9a-zA-Z_-])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i
        emailCheck = emailChanged ? regExpEmail.test(this.userModalData.editUserQueryData.email) : true

        // regExpHp = /^\d{3}-\d{4}-\d{4}$/
        hpCheck = true
        // hpCheck = hpChanged ? regExpHp.test(this.userModalData.editUserQueryData.hp) : true
        
        if(!fillDataCheck) {

          this.$vs.notify({
            title:'미입력',
            text:"빠짐없이 입력 하세요.",
            color:'warning'})

            return false;
        }
        
        if(!nameCheck) {

          this.$vs.notify({
            title:'이름',
            text:"완전한한글, 영문, 숫자, _로 2~20글자 입력해야 하며, 특수문자를 입력할 수 없습니다.",
            color:'warning'})
        }
        if(!accountIdCheck) {

          this.$vs.notify({
            title:'아이디',
            text:"아이디는 6~12 글자, 공백 및 특수문자를 포함할 수 없습니다.",
            color:'warning'})
        }
        if(!passwordCheck) {

          this.$vs.notify({
            title:'비밀번호',
            text:"비밀번호는 8~15개의 영문 대소문자, 1개 이상의 특수문자를 반드시 포함해야 합니다.",
            color:'warning'})
        }
        if(!emailCheck) {

          this.$vs.notify({
            title:'이메일',
            text:"올바른 형식의 이메일을 입력해주세요.",
            color:'warning'})
        }
        if(!hpCheck) {

          this.$vs.notify({
            title:'전화번호',
            text:"올바른 형식의 전화번호를 입력해주세요.",
            color:'warning'})
        }

        return nameCheck && accountIdCheck && passwordCheck && emailCheck && hpCheck
      }
      else {
        
        return false
      }
    },

    searchUserList() {
      this.resetPagePosition()
      this.updateSearchText()
      this.getUserList()
    },

    getUserList() {
      this.$vs.loading({
        container: "#button-with-loading",
        scale: 0.6
      });
      const thisIns = this;

      if(this.$route.query.deleted){
        this.renderMethod.delFrag = 0;
      }else{
        this.renderMethod.delFrag = 1;
      }
      this.$http
        .post("/api/manageUsers/reporterlist", this.buildSearchParam())
        .then(function(response) {
          thisIns.numOfUsers = response.data[0][0].user_count;
          thisIns.numOfPages = parseInt(
            response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage
          );
          thisIns.users = response.data[1];

          thisIns.$vs.loading.close("#button-with-loading > .con-vs-loading");
        })
        .catch(function(error) {
          var errorText = error.toString();
          if(errorText.includes('401')){
            alert('로그인 해주세요!')
            thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
          }
          thisIns.$vs.notify({
            title: "Error",
            text: error,
            color: "danger",
            iconPack: "feather",
            icon: "icon-alert-circle"
          });
        });
    },

    buildSearchParam() {

      var param = {};

      param.delFrag = this.renderMethod.delFrag;
      param['level'] = this.renderMethod.level
      param['startRow'] = this.renderMethod.startRow
      param['itemsPerPage'] = this.renderMethod.itemsPerPage
      param['factor'] = this.renderMethod.factor
      param['searchText'] = this.searchText

      return param
    },

    resetPagePosition() {
      this.currentx = 1;
      this.renderMethod.startRow = 0;
    },

    updateSearchText() {
      this.searchText = this.renderMethod.inputText
    },
    showSidebar() {
        this.$store.commit('TOGGLE_IS_SIDEBAR_ACTIVE', true);
    },
  },
  created() {

    if(this.$store.state.AppActiveUser.level >= 10) {
      
      this.userModalData.optionData.levelOption.push({ text: "관리자", value: "10" })
    }
    
    if(this.$route.query.deleted){
      this.renderMethod.delFrag = 0;
    }

    const thisIns = this;

    this.$http
      .post("/api/manageUsers/reporterlist", thisIns.renderMethod)
      .then(function(response) {
        thisIns.numOfUsers = response.data[0][0].user_count;
        thisIns.numOfPages = parseInt(
          response.data[0][0].user_count / thisIns.renderMethod.itemsPerPage
        );
        thisIns.users = response.data[1];
      })
      .catch(function(error) {
        var errorText = error.toString();
        if(errorText.includes('401')){
          alert('로그인 해주세요!')
          console.log(thisIns.$router)
          thisIns.$router.push({path: '/pages/login?to='+ thisIns.$router.history.current.path, query: thisIns.$router.history.current.query})
        }
        thisIns.$vs.notify({
          title: "Error",
          text: error,
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-circle"
        });
      });
  },
  mounted() {
    this.isMounted = true;
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/custom-style/vs-table-reporter.scss";
</style>